import { Component, Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./components/privateroutes/PrivateRoute";
import {
  MainPage,
  AboutPage,
  ServicesPage,
  ContactPage,
  DashboardPage,
  CreateProject,
  LoginPage,
  UsersDashboardPage,
  ProjectsforDashboard,
  NotFound,
} from "./components/index_components";
import { currentUser } from "./actions/auth_actions";
import { jwtDecode } from "jwt-decode";
import { fetchData } from "./actions/site_action";
import Projects_dashboard from "./components/dashboardpage/projects/projects_dashboard";
import SingleProject from "./components/dashboardpage/projects/single_project";
class AppforStore extends Component {
  constructor() {
    super();
    this.state = {
      site: {},
      services: [
        {
          name: "Bathroom Remodel",
          serviceId: "201564094514635045",
          description:
            "Customized bathroom remodeling to enhance comfort and functionality.",
          whatsIncluded: [
            "Fixture upgrades",
            "Tile installation",
            "Custom shower design",
            "Plumbing and electrical work",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_a-white-luxury-bathroom-with-marble-walls-and-floor-ai-generate_26311755.jpg",
        },
        {
          name: "Kitchen Remodel",
          serviceId: "201565295100608806",
          description:
            "Innovative kitchen remodeling solutions tailored to your lifestyle and preferences.",
          whatsIncluded: [
            "Cabinet installation",
            "Countertop selection",
            "Appliance upgrades",
            "Lighting design",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_modern-kitchen-design-with-stainless-steel-appliances-and_25492953.jpg",
        },
        {
          name: "Interior Design",
          serviceId: "122419028794040611",
          description:
            "Creative and functional interior design solutions tailored to your preferences.",
          whatsIncluded: [
            "Space planning",
            "Color consultation",
            "Furniture selection",
            "Decor accessories",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_luxurious-and-modern-kitchen-interior_28217478.jpg",
        },
        {
          name: "Interior Painting",
          serviceId: "122681972262289742",
          description:
            "Professional interior painting services to refresh and enhance your living spaces.",
          whatsIncluded: [
            "Surface preparation",
            "Premium paint products",
            "Detailed finish work",
            "Clean-up",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_empty-room-with-grey-wall-and-wood-floor-3d-rendering_7114234.jpg",
        },

        // bad az olaviat
        {
          name: "General Contracting",
          serviceId: "114400570923630858",
          description:
            "Comprehensive contracting services for various construction projects.",
          whatsIncluded: [
            "Project management",
            "Material procurement",
            "Labor services",
            "Quality assurance",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_engineer-contractor-s-desk_18789598.jpg",
        },
        {
          name: "Tile Installation and Replacement",
          serviceId: "123455490521014685",
          description:
            "Expert installation and replacement of tiles for floors, walls, and other surfaces.",
          whatsIncluded: [
            "Tile layout design",
            "Subfloor preparation",
            "Precision installation",
            "Grout and sealing",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_ceramic-tiles-and-tools-for-tiler_11921832.jpg",
        },
        {
          name: "Home Modification for Accessibility",
          serviceId: "135220836543398196",
          description:
            "Adaptations and modifications to make your home more accessible and user-friendly.",
          whatsIncluded: [
            "Ramp installation",
            "Widening doorways",
            "Grab bar installation",
            "Accessible bathroom modifications",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_ai-generated-a-wheelchair-and-a-hospital-hallway_35849014.jpg",
        },
        {
          name: "Construction Services",
          serviceId: "136169012491772391",
          description:
            "A wide range of construction services to bring your building projects to life.",
          whatsIncluded: [
            "Site preparation",
            "Foundation work",
            "Structural framing",
            "Finishing touches",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_contractor-s-desk-and-engineering-team-there-are_18789597.jpg",
        },
        {
          name: "Home Remodeling",
          serviceId: "173654163739845081",
          description:
            "Transformative remodeling services to update and improve your home.",
          whatsIncluded: [
            "Design consultation",
            "Demolition and removal",
            "Customized upgrades",
            "Project supervision",
          ],
          itemSrc:
            "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_man-cutting-laminate-floor-plank-with-electrical-circular-saw_11947736.jpg",
        },

        // {
        //   name: "Garage Addition or Remodel",
        //   serviceId: "283054400850764177",
        //   description:
        //     "Additions and remodeling services to optimize and enhance your garage space.",
        //   whatsIncluded: [
        //     "Storage solutions",
        //     "Floor coating",
        //     "New door installation",
        //     "Electrical upgrades",
        //   ],
        //   itemSrc:
        //     "https://projects-images-remodel.s3.us-west-1.amazonaws.com/vecteezy_construction-worker-installing-a-new-roof_11920656.jpg",
        // },
      ],
    };
  }

  componentDidMount() {
    this.props.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Check if the relevant data has changed
    if (prevProps.site !== this.props.site) {
      const site = this.props.site; // Replace with the actual property

      // Check if the new data is different from the current state
      if (site !== this.state.site) {
        // Update the state only if it's different to avoid infinite loop
        this.setState({ site }, () => {
          // Optional callback function that runs after the state is updated
          console.log("State updated:", this.state.site);
        });
      }
    }
  }

  render() {
    var { services } = this.state;
    var { site } = this.state;
    var decodedUser = null;
    console.log("user params", this);

    console.log();
    if (localStorage.getItem("token")) {
      decodedUser = jwtDecode(localStorage.getItem("token"));
    }

    return (
      <Fragment>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<MainPage site={site.data} services={services} />}
            />
            <Route path="/about" element={<AboutPage site={site.data} />} />
            <Route
              path="/services"
              element={<ServicesPage services={services} />}
            />
            <Route path="/contact" element={<ContactPage />} />

            <Route path="/login" element={<LoginPage />} />
            {/* auth routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute user={decodedUser !== null && decodedUser}>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            {decodedUser && (
              <Route
                path="/dashboard/settings/projects"
                element={
                  <PrivateRoute user={decodedUser !== null && decodedUser}>
                    <ProjectsforDashboard
                      user={{
                        username:
                          decodedUser !== null &&
                          decodedUser["cognito:username"],
                        email: decodedUser && decodedUser.email,
                        sub: decodedUser && decodedUser.sub,
                      }}
                      site={site.data}
                    />
                  </PrivateRoute>
                }
              />
            )}
            <Route
              path="/dashboard/settings/projects/:id"
              element={
                <PrivateRoute user={decodedUser !== null && decodedUser}>
                  <SingleProject
                    user={{
                      username:
                        decodedUser !== null && decodedUser["cognito:username"],

                      email: decodedUser !== null && decodedUser.email,
                      sub: decodedUser !== null && decodedUser.sub,
                    }}
                    site={site.data}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/settings/createproject"
              element={
                <PrivateRoute user={decodedUser !== null && decodedUser}>
                  <CreateProject
                    user={{
                      username:
                        decodedUser !== null && decodedUser["cognito:username"],
                      email: decodedUser !== null && decodedUser.email,
                      sub: decodedUser !== null && decodedUser.sub,
                    }}
                    site={site.data}
                  />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard/settings/users"
              element={
                <PrivateRoute user={decodedUser !== null && decodedUser}>
                  <UsersDashboardPage
                    auth={decodedUser !== null && decodedUser}
                    site={site.data}
                  />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, { fetchData })(AppforStore);

// const Appold = () => {
//   const services = [
//     {
//       name: "General Contracting",
//       serviceId: "114400570923630858",
//       description:
//         "Comprehensive contracting services for various construction projects.",
//       whatsIncluded: [
//         "Project management",
//         "Material procurement",
//         "Labor services",
//         "Quality assurance",
//       ],
//     },
//     {
//       name: "Interior Design",
//       serviceId: "122419028794040611",
//       description:
//         "Creative and functional interior design solutions tailored to your preferences.",
//       whatsIncluded: [
//         "Space planning",
//         "Color consultation",
//         "Furniture selection",
//         "Decor accessories",
//       ],
//     },
//     {
//       name: "Interior Painting",
//       serviceId: "122681972262289742",
//       description:
//         "Professional interior painting services to refresh and enhance your living spaces.",
//       whatsIncluded: [
//         "Surface preparation",
//         "Premium paint products",
//         "Detailed finish work",
//         "Clean-up",
//       ],
//     },
//     {
//       name: "Tile Installation and Replacement",
//       serviceId: "123455490521014685",
//       description:
//         "Expert installation and replacement of tiles for floors, walls, and other surfaces.",
//       whatsIncluded: [
//         "Tile layout design",
//         "Subfloor preparation",
//         "Precision installation",
//         "Grout and sealing",
//       ],
//     },
//     {
//       name: "Home Modification for Accessibility",
//       serviceId: "135220836543398196",
//       description:
//         "Adaptations and modifications to make your home more accessible and user-friendly.",
//       whatsIncluded: [
//         "Ramp installation",
//         "Widening doorways",
//         "Grab bar installation",
//         "Accessible bathroom modifications",
//       ],
//     },
//     {
//       name: "Construction Services",
//       serviceId: "136169012491772391",
//       description:
//         "A wide range of construction services to bring your building projects to life.",
//       whatsIncluded: [
//         "Site preparation",
//         "Foundation work",
//         "Structural framing",
//         "Finishing touches",
//       ],
//     },
//     {
//       name: "Home Remodeling",
//       serviceId: "173654163739845081",
//       description:
//         "Transformative remodeling services to update and improve your home.",
//       whatsIncluded: [
//         "Design consultation",
//         "Demolition and removal",
//         "Customized upgrades",
//         "Project supervision",
//       ],
//     },
//     {
//       name: "Roof Installation or Replacement",
//       serviceId: "174454027063083482",
//       description:
//         "Professional installation or replacement of roofs for residential or commercial properties.",
//       whatsIncluded: [
//         "Roof inspection",
//         "Material selection",
//         "Installation by skilled professionals",
//         "Cleanup",
//       ],
//     },
//     {
//       name: "Roof Repair or Maintenance",
//       serviceId: "174455213291954651",
//       description:
//         "Timely and efficient repair and maintenance services to extend the life of your roof.",
//       whatsIncluded: [
//         "Leak detection and repair",
//         "Shingle replacement",
//         "Gutter cleaning",
//         "Roof inspection",
//       ],
//     },
//     {
//       name: "Bathroom Remodel",
//       serviceId: "201564094514635045",
//       description:
//         "Customized bathroom remodeling to enhance comfort and functionality.",
//       whatsIncluded: [
//         "Fixture upgrades",
//         "Tile installation",
//         "Custom shower design",
//         "Plumbing and electrical work",
//       ],
//     },
//     {
//       name: "Kitchen Remodel",
//       serviceId: "201565295100608806",
//       description:
//         "Innovative kitchen remodeling solutions tailored to your lifestyle and preferences.",
//       whatsIncluded: [
//         "Cabinet installation",
//         "Countertop selection",
//         "Appliance upgrades",
//         "Lighting design",
//       ],
//     },
//     {
//       name: "Room Remodel",
//       serviceId: "212284244981498182",
//       description:
//         "Remodeling services to update and revitalize specific rooms in your home.",
//       whatsIncluded: [
//         "Flooring installation",
//         "Painting and finishing",
//         "Custom storage solutions",
//         "Lighting upgrades",
//       ],
//     },
//     {
//       name: "New Home Construction",
//       serviceId: "212373118399357257",
//       description:
//         "Professional construction services for the creation of new homes.",
//       whatsIncluded: [
//         "Architectural design",
//         "Permit acquisition",
//         "Construction management",
//         "Final inspections",
//       ],
//     },
//     {
//       name: "Patio Remodel or Addition",
//       serviceId: "242224544494477771",
//       description:
//         "Enhancements and additions to create a comfortable and stylish outdoor living space.",
//       whatsIncluded: [
//         "Patio design",
//         "Hardscaping",
//         "Outdoor furniture selection",
//         "Lighting installation",
//       ],
//     },
//     {
//       name: "Basement Finishing or Remodeling",
//       serviceId: "242254535857062374",
//       description:
//         "Transform your basement into a functional and inviting living space with our finishing and remodeling services.",
//       whatsIncluded: [
//         "Insulation and framing",
//         "Drywall installation",
//         "Flooring options",
//         "Custom lighting",
//       ],
//     },
//     {
//       name: "Garage Addition or Remodel",
//       serviceId: "283054400850764177",
//       description:
//         "Additions and remodeling services to optimize and enhance your garage space.",
//       whatsIncluded: [
//         "Storage solutions",
//         "Floor coating",
//         "New door installation",
//         "Electrical upgrades",
//       ],
//     },
//   ];
//   if (localStorage.getItem("token")) {
//     var decodedUser = jwtDecode(localStorage.getItem("token"));

//     console.log("user loaded successfully", decodedUser);
//   }

//   const dispatch = useDispatch();

//   const [dataApp, setDataApp] = useState(null);
//   const dataFromRedux = useSelector((state) => state.site); // Replace 'dataFromRedux' with your actual state name

//   // Set up the useDispatch hook
//   useEffect(() => {
//     // Call your firstFunction when the component mounts
//     dispatch(fetchData());
//     setDataApp(dataFromRedux);
//   }, [dispatch]);
//   console.log("we got alldata in hooks", dataFromRedux);

//   return (
//     <Provider store={store}>
//       {/* <Fragment> */}
//       <Router>
//         <Routes>
//           <Route
//             path="/"
//             element={<MainPage site={{}} services={services} />}
//           />
//           <Route path="/about" element={<AboutPage />} />
//           <Route
//             path="/services"
//             element={<ServicesPage services={services} />}
//           />
//           <Route path="/contact" element={<ContactPage />} />

//           <Route path="/login" element={<LoginPage />} />
//           {/* auth routes */}
//           <Route
//             path="/dashboard"
//             element={
//               <PrivateRoute user={decodedUser}>
//                 <DashboardPage />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             path="/dashboard/settings/createproject"
//             element={
//               <PrivateRoute user={decodedUser}>
//                 <CreateProject auth={decodedUser} />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             path="/dashboard/settings/users"
//             element={
//               <PrivateRoute user={decodedUser}>
//                 <UsersDashboardPage auth={decodedUser} />
//               </PrivateRoute>
//             }
//           />
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </Router>
//       {/* </Fragment> */}
//     </Provider>
//   );
// };
